import headerSlugify from 'utils/headerSlugify'

// Util to parse htmlAst from docs/blog to generate multi depth object for TOC
export default function tocParser (contents, maxDepth = 5) {
  let toc = []

  const getElementText = el => {
    const t = el.children
      // .filter(c => c.type === 'text')
      .reduce((a, c) => {
        a = a + c.value
        return a
      }, '')

    return t
  }
  const getElement = el => {
    const text = getElementText(el)

    const hash = `${headerSlugify(text)}`

    return {
      text,
      level: el.depth,
      children: [],
      hash
    }
  }

  const updateTOC = (e, toc, level, current = 0) => {
    if (level === current) {
      toc.push(e)
    } else {
      if (!toc.length) {
        toc.push({
          text: '-',
          hash: null,

          children: []
        })
      }

      toc[toc.length - 1].children = updateTOC(
        e,
        toc[toc.length - 1].children,
        level,
        (current += 1)
      )
    }
    return toc
  }

  const children = contents.children || []

  for (const c of children) {
    if (c.type === 'heading') {
      if (c.depth <= maxDepth) {
        const e = getElement(c)
        toc = updateTOC(e, toc, c.depth - 2)
      }
    }
  }

  return toc
}
