import React, { useRef, useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { List } from '@chakra-ui/core'
import useThrottledOnScroll from 'utils/throttledScroll'
import { uid } from 'react-uid'
import ScrollableListItem from 'elements/TableOfContents/ScrollableListItem'

function getLinks (items) {
  const links = []

  items.filter(i => i.hash).forEach(item2 => {
    links.push({
      ...item2,
      node: document.getElementById(item2.hash)
    })

    if (item2.children.length > 0) {
      item2.children.forEach(item3 => {
        links.push({
          ...item3,
          node: document.getElementById(item3.hash)
        })
      })
    }
  })
  return links
}

export default function TableOfContents (props) {
  const { contents, title } = props

  const itemsClientRef = useRef([])
  useEffect(() => {
    itemsClientRef.current = getLinks(contents)
  }, [contents])

  const [activeState, setActiveState] = useState(null)

  const clickedRef = useRef(false)
  const unsetClickedRef = useRef(null)

  const findActiveIndex = useCallback(() => {
    // Don't set the active index based on scroll if a link was just clicked
    if (clickedRef.current) {
      return
    }

    let active
    for (let i = itemsClientRef.current.length - 1; i >= 0; i -= 1) {
      // No hash if we're near the top of the page
      if (document.documentElement.scrollTop < 200) {
        active = { hash: null }
        break
      }

      const item = itemsClientRef.current[i]

      if (process.env.NODE_ENV !== 'production') {
        if (!item.node) {
          console.error(
            `Missing node on the item ${JSON.stringify(item, null, 2)}`
          )
        }
      }

      if (
        item.node &&
        item.node.offsetTop <
          document.documentElement.scrollTop +
            document.documentElement.clientHeight / 8
      ) {
        active = item
        break
      }
    }

    if (active && activeState !== active.hash) {
      setActiveState(active.hash)
    }
  }, [activeState])

  useThrottledOnScroll(contents.length > 0 ? findActiveIndex : null, 166)

  useEffect(
    () => () => {
      clearTimeout(unsetClickedRef.current)
    },
    []
  )

  if (!contents.length) return null
  return (

    <nav>
      {/* <Heading mb="3" as="h3" textTransform="uppercase" size="sm" fontWeight={500} color="gray"> {title}</Heading> */}
      <List borderLeftWidth={2} borderLeftColor="gray.300" spacing={3} pl={4} >

        {contents.map((item2, i) => (

          <ScrollableListItem
            key={uid(item2)}
            offset="90"
            fontSize= ".9rem"
            fontWeight= "700"
            color={ activeState === item2.hash ? 'gray.900' : 'gray.500' }
            name={item2.text}
            to={`#${item2.hash}`}
          >
            {item2.children.length > 0 ? (
              <List spacing={3} mt={3} ml={2}>
                {item2.children.map((item3, i) => (
                  <ScrollableListItem
                    offset="90"
                    key={uid(item3)}
                    fontSize= ".8rem"
                    fontWeight= "300"
                    color={ activeState === item3.hash ? 'gray.900' : 'gray.500' }
                    name={item3.text}
                    to={`#${item3.hash}`}
                  />
                ))}
              </List>
            ) : null}
          </ScrollableListItem>
        ))}
      </List>
    </nav>

  )
}
TableOfContents.defaultProps = {
  title: 'On this page'
}
TableOfContents.propTypes = {
  contents: PropTypes.array.isRequired,
  title: PropTypes.string
}
