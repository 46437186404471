import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import tocParser from 'utils/tocParser'
import TableOfContents from 'elements/TableOfContents/TableOfContents'
import { Flex, Box } from '@chakra-ui/core'
import SEO from 'elements/Frame/SEO'
import Title from 'elements/Typography/Title'
import Article from 'elements/Frame/Article'
import MdxRenderer from 'elements/Markdown/MdxRenderer'

export default function DocTemplate ({ data }) {
  const { mdx } = data
  const {
    frontmatter: { title, description, excerpt },
    body,
    mdxAST
  } = mdx

  return (
    <Flex>
      <Box flex="1">
        <SEO title={title} description={description || excerpt} />
        <Article flex="1">
          <header>
            <Title>{title}</Title>
          </header>
          <MdxRenderer body={body} />
        </Article>
      </Box>
      <Box flex="0 0 200px" p="5">
        <TableOfContents contents={tocParser(mdxAST)} />
      </Box>
    </Flex>

  )
}

DocTemplate.propTypes = {
  data: PropTypes.object.isRequired
}

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      headings {
        depth
        value
      }
      body
      mdxAST
      frontmatter {
        path
        title
      }
    }
  }
`
