import React, { useMemo, forwardRef } from 'react'
import PropTypes from 'prop-types'
import { ListItem, Box } from '@chakra-ui/core'
import AnchorLink from 'react-anchor-link-smooth-scroll'

export default function ScrollableListItem (props) {
  const { name, to, offset, children } = props

  const renderLink = useMemo(
    () =>
      // eslint-disable-next-line react/display-name
      forwardRef((itemProps, ref) => (
        <AnchorLink href={to} offset={offset} {...itemProps} ref={ref} />
      )),
    [to]
  )

  return (
    <ListItem {...props} >
      <Box as={renderLink}>{name}</Box>
      {children}
    </ListItem>
  )
}

ScrollableListItem.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  offset: PropTypes.string
}
